import React from 'react'

function Textslider() {
  return (
    <div>
      <div className='bh_x'>
    <div class="containe-x">
    <div class="horizontal-scrolling-items">
    
    <div class="horizontal-scrolling-items__item">
    <span>$SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY </span><span>PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  </span>
    </div>
    
    <div class="horizontal-scrolling-items__item">
    <span>$SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY </span><span>PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  </span>  
    </div>
    </div>
    </div> 
    <div class="containe-x2">
    <div class="horizontal-scrolling-items ewx">
    
    <div class="horizontal-scrolling-items__item ewx2">
    <span>$SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY </span><span>PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  </span>  
    </div>
    
    <div class="horizontal-scrolling-items__item ewx2">
    <span>$SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY </span><span>PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  $SOLTY PRESALE  </span>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Textslider